export default function() {

  /**
   * Dismiss a given element and remove it from the DOM. This function is intended
   * to be used on nonpersistent elements such as notifications or alerts.
   */

  let triggers = document.querySelectorAll('[data-dismiss]');

  triggers.forEach(function(trigger) {

    /**
     * Query the existence of the target element. If the element does not exist,
     * stop executing the function.
     */

    const target = document.querySelector(trigger.dataset.dismiss);
    if (!target) { return; }

    trigger.addEventListener('click', function() {

      const state = 'is-dismissable';
      const dismissable = target.classList.contains(state);

      /**
       * Dismiss the target element.
       */

      if (dismissable === true) {
        target.classList.remove(state);
        target.setAttribute('aria-hidden', true);
        trigger.setAttribute('aria-expanded', false);
      }

      /**
       * Wait for one second, then remove the target element from the DOM.
       */

      setTimeout(function() {
        target.parentNode.removeChild(target);
      }, 1000);
    });
  });

}
