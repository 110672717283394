export default function() {

  /**
   * Toggle the visibility of a given element and set associated ARIA attribute values.
   * This function is intended to be used on modal elements with multiple toggle
   * controls (i.e. open and close) and which necessitate focus trapping.
   *
   * @link https://hiddedevries.nl/en/blog/2017-01-29-using-javascript-to-trap-focus-in-an-element
   */

  let anchor;
  let toggles = document.querySelectorAll('[data-modal]');

  toggles.forEach(function(toggle) {

    /**
     * Query the existence of the target element. If the element does not exist,
     * stop executing the function.
     */

    const modal = document.querySelector(toggle.dataset.modal);
    if (!modal) { return; }

    toggle.addEventListener('click', function() {

      const state = 'is-visible';
      const visible = modal.classList.contains(state);

      /**
       * Open the modal and focus its *first* focusable element. Set the clicked
       * toggle as the anchor toggle (for the purpose of returning focus when
       * the modal is dismissed).
       */

      if (visible !== true) {
        modal.classList.add(state);
        modal.removeAttribute('aria-hidden');

        const focusables = modal.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        const focusableFirst = focusables[0];
        const focusableLast = focusables[focusables.length - 1];

        setTimeout(function() {
          focusableFirst.focus();
        }, 100);

        anchor = toggle;
        anchor.setAttribute('aria-expanded', true);

        /**
         * Trap keyboard focus in the modal.
         *
         * 1. If the user presses shift-tab and the focused element is the _first_
         * focusable element in the modal, focus the _last_ focusable element in
         * the modal.
         * 2. If the user presses tab and the focused element is the _last_
         * focusable element in the modal, focus the _first_ focusable element in
         * the modal.
         */

        const KEYCODE_TAB = 9;
        const tabModal = function(e) {
          if (e.key === 'Tab' || e.keyCode === KEYCODE_TAB) {
            if (e.shiftKey) {
              if (document.activeElement === focusableFirst) { /* 1 */
                focusableLast.focus();
                e.preventDefault();
              }
            } else {
              if (document.activeElement === focusableLast) { /* 2 */
                focusableFirst.focus();
                e.preventDefault();
              }
            }
          }
        }

        modal.addEventListener('keydown', tabModal);

        /**
         * If the user presses the escape key, dismiss the modal and return focus
         * to the anchor toggle. Then, reset the anchor toggle.
         */

        const KEYCODE_ESC = 27;
        const escapeModal = function(e) {
          if (e.keyCode === KEYCODE_ESC && modal.classList.contains(state)) {
            modal.classList.remove(state);
            modal.setAttribute('aria-hidden', true);

            anchor.focus();
            anchor.setAttribute('aria-expanded', false);
            anchor = null;
          }
        }

        window.addEventListener('keydown', escapeModal);
      }

      /**
       * Dismiss the modal and return focus to the anchor toggle. Then, reset the
       * anchor toggle.
       */

      else {
        modal.classList.remove(state);
        modal.setAttribute('aria-hidden', true);

        anchor.focus();
        anchor.setAttribute('aria-expanded', false);
        anchor = null;
      }
    });
  });
}
