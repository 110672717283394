export default function() {

  /**
   * Toggle the visibility of `preview` component excerpts and, if set, their
   * corresponding preview images, and set associated ARIA attribute values.
   */

  let toggles = document.querySelectorAll('[data-preview]');
  let viewport;

  toggles.forEach(function(toggle) {

    /**
     * Query the existence of the target and parent elements. If an element does
     * not exist, stop executing the function.
     */

    const excerpt = document.querySelector(toggle.dataset.preview);
    const image = document.querySelector(toggle.dataset.previewImage);
    const parent = document.querySelector(toggle.dataset.parent);

    if (!excerpt || toggle.dataset.previewImage && !image || !parent) { return; }

    /**
     * If a viewport exists, set its minimum height as equal to the height of
     * the tallest visible excerpt.
     */

    viewport = parent.querySelector('[data-preview-viewport]');

    if (viewport) {
      viewport.style.minHeight = (excerpt.offsetHeight > viewport.offsetHeight) ? excerpt.offsetHeight + 'px' : viewport.offsetHeight + 'px';
    }

    toggle.addEventListener('click', function() {

      const state = 'is-visible';
      const visible = excerpt.classList.contains(state);

      /**
       * Loop through siblings of the clicked toggle element and hide their
       * respective target elements.
       */

      let siblings = parent.querySelectorAll('[data-preview]');

      siblings.forEach(function(sibling) {

        /**
         * Query the existence of the target elements. If an element does not
         * exist, stop executing the function.
         */

        const excerpt = parent.querySelector(sibling.dataset.preview);
        const image = parent.querySelector(sibling.dataset.previewImage);
        if (!excerpt || sibling.dataset.previewImage && !image) { return; }

        excerpt.classList.remove(state);
        excerpt.style.display = 'none';
        excerpt.setAttribute('aria-hidden', true);
        sibling.setAttribute('aria-expanded', false);

        if (image) {
          image.style.display = 'none';
        }
      });

      /**
       * Show the target excerpt.
       *
       * 1. If a viewport exists, set its minimum height as equal to the height
       * of the target excerpt.
       */

      if (visible !== true) {
        excerpt.classList.add(state);
        excerpt.style.display = '';
        excerpt.removeAttribute('aria-hidden');
        toggle.setAttribute('aria-expanded', true);

        if (viewport) { /* 1 */
          viewport.style.minHeight = excerpt.offsetHeight + 'px';
        }
      }

      /**
       * Hide the target excerpt.
       */

      else {
        excerpt.classList.remove(state);
        excerpt.style.display = 'none';
        excerpt.setAttribute('aria-hidden', true);
        toggle.setAttribute('aria-expanded', false);
      }

      /**
       * If the target image exists, display it.
       */

      if (image) {
        image.style.display = '';
      }
    });
  });
}
