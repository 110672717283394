export default function() {

  /**
   * Filter a directory of terms by a search query. Terms must be grouped into
   * taxonomies.
   */

  let control = document.querySelector('input[data-filter-directory]');

  if (control) {
    control.addEventListener('keyup', function() {

      /**
       * Query the existence of the target element. If the element does not exist,
       * stop executing the function.
       */

      const directory = document.querySelector(control.dataset.filterDirectory);
      if (!directory) { return; }

      const filterQuery = control.value.toLowerCase();

      const filterTaxonomies = directory.querySelectorAll('[data-filter-taxonomy]');
      filterTaxonomies.forEach(function(taxonomy) {

        /**
         * 1. Reset the `display` property for all taxonomies and terms.
         */

        taxonomy.style.display = ''; /* 1 */
        let containsMatch = false;

        const filterTerms = taxonomy.querySelectorAll('[data-filter-terms]');
        filterTerms.forEach(function(item) {

          item.style.display = ''; /* 1 */
          let term = item.dataset.filterTerms.toLowerCase();

          /**
           * If the term matches the filter query, signify a match and continue.
           * Otherwise, hide the term.
           */

          if (term.indexOf(filterQuery) !== -1) {
            containsMatch = true;
          } else {
            item.style.display = 'none';
          }
        });

        /**
         * If the taxonomy does not contain a matching term, hide it.
         */

        if (containsMatch === false) {
          taxonomy.style.display = 'none';
        }
      });
    });
  }
}
