export default function() {

  /**
   * Add functioning previous and next controls to a scrollable `reel` component.
   * Based on BBC GEL carousel implementation.
   *
   * @link https://bbc.github.io/gel/components/carousels/#introduction
   */

  let controls = document.querySelectorAll('[data-control]');
  let targets = [];

  controls.forEach(function(control) {

    /**
     * Query the existence of the target element. If the element does not exist,
     * stop executing the function. Otherwise, add it to the array of targets.
     */

    const target = document.querySelector(control.dataset.control);
    if (!target) { return; }
    if (targets.indexOf(target) !== 0) { targets.push(target); }

    /**
     * When a control is clicked, scroll its target element the desired distance
     * in the given direction.
     */

    control.addEventListener('click', function() {

      const distance = target.offsetWidth;
      const direction = control.dataset.direction;

      if (direction == '<') {
        target.scrollLeft += -distance;
      } else if (direction == '>') {
        target.scrollLeft += distance;
      }
    });
  });

  targets.forEach(function(target) {

    /**
     * Query the existence of the target's controls. If a control does not exist,
     * stop executing the function.
     */

    const parent = target.parentElement;
    const controlPrevious = parent.querySelector('[data-control][data-direction="<"]');
    const controlNext = parent.querySelector('[data-control][data-direction=">"]');

    if (!controlPrevious || !controlNext) { return; }

    /**
     * Enable and/or disable controls according to the target element's scroll
     * position.
     *
     * @link https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
     */

    let debounced;
    target.addEventListener('scroll', function() {
      window.clearTimeout(debounced);
      debounced = setTimeout(function() {

        let positionStart = target.scrollLeft < 1;
        let positionEnd = target.scrollWidth - target.scrollLeft === target.clientWidth;

        controlPrevious.disabled = positionStart;
        controlNext.disabled = positionEnd;

      }, 100);
    });
  });
}
