export default function() {

  /**
   * Toggle the visibility of a given element and set associated ARIA attribute values.
   * This function is intended to be used on elements with a unique toggle control
   * and which _do not_ necessitate focus trapping.
   */

  let toggles = document.querySelectorAll('[data-toggle]');

  toggles.forEach(function(toggle) {

    /**
     * Query the existence of the target element. If the element does not exist,
     * stop executing the function.
     */

    const target = document.querySelector(toggle.dataset.toggle);
    if (!target) { return; }

    toggle.addEventListener('click', function() {

      const state = 'is-visible';
      const visible = target.classList.contains(state);

      /**
       * Show the target element.
       */

      if (visible !== true) {
        target.classList.add(state);
        target.removeAttribute('aria-hidden');
        toggle.setAttribute('aria-expanded', true);

        /**
         * If the toggle control is part of a group, find its sibling toggle
         * controls and hide their target elements.
         */

        if (toggle.dataset.toggleGroup) {

          let siblings = document.querySelectorAll('[data-toggle-group="' + toggle.dataset.toggleGroup + '"]');
          if (!siblings) { return; }

          siblings.forEach(function(sibling) {
            if (sibling != toggle) {
              const target = document.querySelector(sibling.dataset.toggle);

              target.classList.remove(state);
              target.setAttribute('aria-hidden', true);
              sibling.setAttribute('aria-expanded', false);
            }
          });
        }
      }

      /**
       * Hide the target element.
       */

      else {
        target.classList.remove(state);
        target.setAttribute('aria-hidden', true);
        toggle.setAttribute('aria-expanded', false);
      }
    });
  });
}
