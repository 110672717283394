export default function() {

  var languagelinks   = $('#language-switch a');

  languagelinks.on('click', function() {
    var langie = this.lang;
    setCookie("clientlang", langie, 365);
  });

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  function getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
      if (val.indexOf(name) === 0){
        res = val.substring(name.length);
      }
    })
    return res;
  }

  $( document ).ready(function() {
    var referrer =  document.URL;
    var cookieval = getCookie('clientlang');

    if (cookieval != undefined) {
      // have cookie
      if (referrer == "https://local.ilvo.vlaanderen.be/" || referrer == "https://staging.ilvo.vlaanderen.be/" || referrer == "https://ilvo.vlaanderen.be/" ){
        var cllangcookie = cookieval;
        window.location.href = "/"+cllangcookie;
      }
    } else {
      // no cookie
      console.log( "no cookie!" );
    }

  });
}
