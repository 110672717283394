export default function() {

  var searchInput   = $('#search');
  var searchForm    = searchInput.closest('form');
  var searchResults = $('#search-results');

  var search = debounce(function (){
    var id = $(this).data('searchconfig');
    var q = $(this).val();

    $.get( '/actions/elasticsearch-module/search/search?id=' + id + '&q=' + q, function (data) {
      searchResults.empty();

      data.results.forEach(function(entry) { /* 1 */
        if (entry.title){
        //if (entry.url && entry.title) {
          var result = buildSearchResult(entry);
          searchResults.append(result);
        }
      });
    });
  }, 200);

  searchInput.on('input', search);


  /**
   * Show/hide the list of results when the search input is focused/defocused.
   */
  searchInput.on('focus', function(e) {
    setTimeout(function() {
      searchResults.addClass('is-visible');
    }, 300);
  });

  searchInput.on('focusout', function(e) {
    setTimeout(function() {
      searchResults.removeClass('is-visible');
    }, 150);
  });

  searchInput.on('change', function(e) {
    if (searchInput.val() !== '') {
      searchForm.addClass('has-query');
    } else {
      setTimeout(function() {
        searchForm.removeClass('has-query');
      }, 300);
    }
  });


  /**
   * Build search result HTML from Content Template element.
   * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/template
   */

  function buildSearchResult(result) {

    var template = document.getElementById('search-result');
    var html = template.innerHTML;

    if (result.url){
      html = html.replace(/{{ url }}/g,   result.url);
    }
    html = html.replace(/{{ title }}/g, result.title);
    html = html.replace(/{{ highlightedText }}/g, result.highlightedText);

    var placeholder = document.createElement('div');
    placeholder.innerHTML = html.trim();
    var node = placeholder.firstChild;

    return node;
  }

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
}
