export default function() {

  /**
   * Toggle the visibility of a given element and set associated ARIA attribute values.
   * This function is intended to be used on elements with a unique toggle control
   * and which _do not_ necessitate focus trapping.
   */

  let triggers = document.querySelectorAll('[data-pocket]');

  triggers.forEach(function(trigger) {

    /**
     * Query the existence of the target element. If the element does not exist,
     * stop executing the function.
     */

    const target = document.querySelector(trigger.dataset.pocket);
    if (!target) { return; }

    /**
     * If the target element is _not_ overflowed by its content, disable the
     * trigger element.
     *
     * 1. Recalculate values when the window is resized.
     */

    let underflow = function(target) {
      return target.scrollHeight <= target.clientHeight;
    }

    trigger.disabled = underflow(target);

    let debounced;
    window.addEventListener('resize', function() { /* 1 */
      window.clearTimeout(debounced);
      debounced = setTimeout(function() {
        trigger.disabled = underflow(target);
      }, 100);
    });

    /**
     * When the trigger element is clicked, reveal the target element and remove
     * the trigger element from the DOM.
     */

    trigger.addEventListener('click', function() {

      const state = 'is-visible';
      const visible = target.classList.contains(state);

      if (visible !== true) {
        target.classList.add(state);
        trigger.parentNode.removeChild(trigger);
      }
    });
  });
}
