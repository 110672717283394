import 'jquery';

import { default as dismiss } from './triggers/trigger-dismiss.js';
import { default as filterDirectory } from './triggers/trigger-filter-directory.js';
import { default as modal } from './triggers/trigger-modal.js';
import { default as languageSwitcher } from './triggers/trigger-language-switcher.js';
import { default as previewer } from './triggers/trigger-previewer.js';
import { default as pocket } from './triggers/trigger-pocket.js';
import { default as reel } from './controls/controls-reel.js';
import { default as searchResults } from './triggers/trigger-search-results.js';
import { default as toggle } from './triggers/trigger-toggle.js';

dismiss();
filterDirectory();
modal();
languageSwitcher();
previewer();
pocket();
reel();
searchResults();
toggle();
